import React from "react";




export const UnstoppableDomain = () => {

  return (

    <svg

      width="150"

      height="48"

      viewBox="0 0 180 48"

      fill="none"

      xmlns="http://www.w3.org/2000/svg"

      class="css-1bncsgz-logo"

    >

      <path

        d="M43.4917 5.14258V19.9488L0 37.4439L43.4917 5.14258Z"

        fill="#00C9FF"

      ></path>

      <path

        d="M35.3383 4.5V30.0417C35.3383 33.611 33.9063 37.0342 31.3573 39.5581C28.8083 42.0821 25.3511 43.5 21.7463 43.5C18.1415 43.5 14.6843 42.0821 12.1353 39.5581C9.58631 37.0342 8.1543 33.611 8.1543 30.0417V19.2791L16.3081 14.8359V30.0417C16.2318 30.7053 16.2979 31.3774 16.5021 32.014C16.7064 32.6506 17.0441 33.2372 17.4933 33.7355C17.9425 34.2338 18.4929 34.6325 19.1085 34.9055C19.7242 35.1785 20.3911 35.3197 21.0657 35.3197C21.7402 35.3197 22.4072 35.1785 23.0228 34.9055C23.6384 34.6325 24.1889 34.2338 24.638 33.7355C25.0872 33.2372 25.425 32.6506 25.6292 32.014C25.8335 31.3774 25.8996 30.7053 25.8232 30.0417V9.65439L35.3383 4.5Z"

        fill="#0D67FE"

      ></path>

      <path

        d="M63.336 11.0293V17.6332C63.336 19.848 62.0637 21.0197 60.4767 21.0197C59.1839 21.0197 58.1715 20.2747 58.1715 18.2495V11.0293H55.8047V18.4188C55.8047 21.2568 57.337 23.0856 59.8542 23.0856C60.5664 23.0927 61.267 22.9073 61.8806 22.5492C62.4942 22.1911 62.9974 21.674 63.336 21.0536H63.3771V22.7401H65.7029V11.0293H63.336Z"

        fill="#0D67FE"

      ></path>

      <path

        d="M73.6641 10.69C72.9355 10.6781 72.217 10.8604 71.5839 11.2179C70.9509 11.5754 70.4266 12.0949 70.066 12.722H70.025V11.0287H67.6992V22.7463H70.066V16.1424C70.066 13.9276 71.3794 12.7558 73.0348 12.7558C74.4029 12.7558 75.47 13.5009 75.47 15.5261V22.7463H77.8368V15.3364C77.8368 12.5188 76.2703 10.69 73.6641 10.69Z"

        fill="#0D67FE"

      ></path>

      <path

        d="M84.8689 15.6494H84.4175C82.3653 15.6494 81.9617 15.2904 81.9617 14.376C81.9617 13.4616 82.7826 12.7098 84.308 12.7098C84.6002 12.6871 84.8939 12.7251 85.1703 12.8216C85.4468 12.918 85.6997 13.0706 85.913 13.2697C86.1263 13.4687 86.2951 13.7098 86.4086 13.9773C86.5222 14.2448 86.5779 14.5329 86.5722 14.8231H89.0416C88.8706 12.4592 87.1058 10.6914 84.308 10.6914C81.1067 10.6914 79.5197 12.5608 79.5197 14.376C79.5197 16.4825 81.2845 17.6949 83.5008 17.6949H84.1438C86.196 17.6949 86.8116 18.169 86.8116 19.2934C86.8116 20.2958 85.7787 21.0612 84.2328 21.0612C83.6093 21.0945 82.9978 20.8826 82.5315 20.4716C82.0651 20.0605 81.7816 19.4837 81.7428 18.8666H79.2734C79.4239 21.2982 81.4419 23.0863 84.1986 23.0863C87.5093 23.0863 89.2331 21.1898 89.2331 19.2934C89.2673 17.1192 87.5367 15.6494 84.8689 15.6494Z"

        fill="#0D67FE"

      ></path>

      <path

        d="M96.7032 13.0755V11.0435H93.495V9.41797H91.1351V11.03H89.1992V13.0619H91.1351V19.5981C91.1351 21.7926 92.298 22.734 94.4937 22.734H96.7101V20.7021H94.7058C94.541 20.7117 94.3761 20.6858 94.2224 20.6262C94.0687 20.5666 93.9298 20.4748 93.8154 20.357C93.701 20.2392 93.6137 20.0982 93.5596 19.9438C93.5055 19.7894 93.4858 19.6253 93.5019 19.4626V13.0755H96.7032Z"

        fill="#0D67FE"

      ></path>

      <path

        d="M103.373 10.6914C100.055 10.6914 97.4355 12.9062 97.4355 16.8889C97.4355 20.8715 100.055 23.0863 103.373 23.0863C106.691 23.0863 109.311 20.8512 109.311 16.8889C109.311 12.9266 106.684 10.6914 103.373 10.6914ZM103.373 21.0612C101.437 21.0612 99.8844 19.7065 99.8844 16.8889C99.8844 14.0712 101.437 12.6895 103.373 12.7098C105.309 12.7301 106.855 14.0306 106.855 16.8889C106.855 19.7472 105.309 21.0408 103.373 21.0612Z"

        fill="#0D67FE"

      ></path>

      <path

        d="M117.315 10.6907C116.51 10.6723 115.714 10.8603 115.005 11.2365C114.295 11.6128 113.696 12.1644 113.265 12.8378H113.211C113.252 12.3704 113.252 12.0318 113.252 11.6051V11.0293H110.885V24.8196H113.252V22.2322C113.252 21.7852 113.252 21.4262 113.211 20.9317H113.252C113.674 21.6165 114.273 22.1773 114.987 22.5559C115.702 22.9346 116.505 23.1175 117.315 23.0856C120.215 23.0856 122.787 20.8911 122.787 16.8204C122.787 12.7497 120.222 10.6907 117.315 10.6907ZM116.713 21.0604C114.517 21.0604 113.245 19.191 113.245 16.8001C113.245 14.5446 114.517 12.7362 116.713 12.7362C118.909 12.7362 120.284 14.3821 120.284 16.8475C120.284 19.313 118.868 21.0808 116.72 21.0604H116.713Z"

        fill="#0D67FE"

      ></path>

      <path

        d="M130.825 10.6907C130.02 10.6723 129.224 10.8603 128.514 11.2365C127.805 11.6128 127.206 12.1644 126.775 12.8378H126.734C126.775 12.3704 126.775 12.0318 126.775 11.6051V11.0293H124.408V24.8196H126.775V22.2322C126.775 21.7852 126.775 21.4262 126.734 20.9317H126.775C127.196 21.6145 127.793 22.1739 128.505 22.5524C129.217 22.931 130.017 23.115 130.825 23.0856C133.725 23.0856 136.263 20.8911 136.263 16.8204C136.263 12.7497 133.732 10.6907 130.825 10.6907ZM130.223 21.0604C128.027 21.0604 126.754 19.191 126.754 16.8001C126.754 14.5446 128.027 12.7362 130.223 12.7362C132.418 12.7362 133.793 14.3821 133.793 16.8475C133.793 19.313 132.364 21.0808 130.223 21.0604Z"

        fill="#0D67FE"

      ></path>

      <path

        d="M148.439 20.7022C148.117 20.7022 147.967 20.5057 147.967 20.1265V14.9721C147.967 12.4998 146.763 10.6914 143.213 10.6914C139.792 10.6914 138.199 12.3915 138.028 14.8231H140.394C140.545 13.4684 141.598 12.7098 143.213 12.7098C144.581 12.7098 145.538 13.3126 145.538 14.2473C145.538 15.0127 144.998 15.48 143.233 15.48H142.289C139.471 15.48 137.576 16.6112 137.576 19.1918C137.576 21.901 139.574 23.127 141.92 23.127C143.664 23.127 145.149 22.3616 145.73 20.7225C145.792 21.9146 146.592 22.7544 148.097 22.7544H149.465V20.7225L148.439 20.7022ZM145.6 17.3156C145.6 19.9571 144.157 21.1018 142.31 21.1018C140.695 21.1018 140.052 20.1671 140.052 19.2121C140.052 18.2571 140.607 17.5459 142.33 17.5459H142.672C144.328 17.5459 145.361 16.9701 145.58 15.9677L145.6 17.3156Z"

        fill="#0D67FE"

      ></path>

      <path

        d="M157.324 10.6912C156.52 10.6726 155.725 10.8606 155.016 11.2369C154.308 11.6132 153.71 12.1649 153.281 12.8383H153.233C153.272 12.4285 153.288 12.017 153.281 11.6055V7.29102H150.914V22.7474H153.281V22.2327C153.288 21.7987 153.272 21.3646 153.233 20.9322H153.281C153.701 21.6144 154.297 22.1735 155.007 22.5521C155.718 22.9307 156.517 23.115 157.324 23.0861C160.231 23.0861 162.796 20.8916 162.796 16.8209C162.796 12.7502 160.231 10.6912 157.324 10.6912ZM156.722 21.0609C154.526 21.0609 153.26 19.1915 153.26 16.8006C153.26 14.5451 154.526 12.7367 156.722 12.7367C158.917 12.7367 160.292 14.3825 160.292 16.848C160.292 19.3134 158.87 21.0812 156.722 21.0609Z"

        fill="#0D67FE"

      ></path>

      <path

        d="M164.273 7.29102V22.7474H166.64V7.29102H164.273Z"

        fill="#0D67FE"

      ></path>

      <path

        d="M180 15.8255C180 12.8859 177.633 10.6914 174.404 10.6914C170.984 10.6914 168.576 13.0078 168.576 16.8889C168.576 20.6141 170.916 23.0863 174.404 23.0863C177.264 23.0863 179.398 21.4269 179.877 19.1443H177.298C177.078 19.7259 176.679 20.2241 176.157 20.5682C175.635 20.9123 175.017 21.0848 174.391 21.0612C172.626 21.0612 171.333 19.8081 171.052 17.722H179.986L180 15.8255ZM171.107 15.6291C171.204 14.8262 171.594 14.0861 172.204 13.5484C172.814 13.0106 173.601 12.7124 174.418 12.7098C175.226 12.7056 176.006 13.0042 176.601 13.5456C177.196 14.0871 177.562 14.8314 177.626 15.6291H171.107Z"

        fill="#0D67FE"

      ></path>

      <path

        d="M65.1557 24.8203V29.0671C65.1557 29.5141 65.1557 29.8799 65.1967 30.3675H65.1557C64.7339 29.6876 64.1374 29.1306 63.4272 28.7534C62.717 28.3762 61.9184 28.1923 61.113 28.2205C58.1853 28.2205 55.6406 30.3676 55.6406 34.3163C55.6406 38.3802 58.1579 40.6222 61.113 40.6222C61.9171 40.638 62.7117 40.4481 63.4199 40.0708C64.1282 39.6934 64.7261 39.1415 65.1557 38.4683H65.1967C65.1557 38.9424 65.1557 39.2811 65.1557 39.7078V40.2835H67.5225V24.8203H65.1557ZM61.7355 38.5902C59.5602 38.5902 58.1374 36.863 58.1374 34.3095C58.1374 31.7561 59.5602 30.2456 61.7355 30.2456C63.9107 30.2456 65.1557 32.0405 65.1557 34.3095C65.1557 36.7005 63.8834 38.597 61.715 38.6173L61.7355 38.5902Z"

        fill="#0D67FE"

      ></path>

      <path

        d="M75.3965 28.2461C72.0789 28.2461 69.459 30.4609 69.459 34.4436C69.459 38.4262 72.0789 40.641 75.3965 40.641C78.7141 40.641 81.334 38.4059 81.334 34.4436C81.334 30.4812 78.7073 28.2461 75.3965 28.2461ZM75.3965 38.6158C73.4607 38.6158 71.9079 37.2612 71.9079 34.4436C71.9079 31.6259 73.4607 30.2442 75.3965 30.2645C77.3324 30.2848 78.8783 31.6191 78.8783 34.4436C78.8783 37.268 77.3324 38.5955 75.3965 38.6158Z"

        fill="#0D67FE"

      ></path>

      <path

        d="M96.368 28.2477C95.6177 28.2266 94.8762 28.4124 94.2264 28.7844C93.5765 29.1563 93.0438 29.6998 92.6879 30.3542H92.6263L92.6879 30.9163C92.4726 30.1306 91.9936 29.4409 91.3296 28.9608C90.6657 28.4807 89.8564 28.2387 89.0351 28.2748C88.333 28.2831 87.6466 28.4821 87.0508 28.8499C86.455 29.2177 85.9725 29.7404 85.6559 30.3609H85.6148V28.5864H83.2891V40.304H85.6559V33.7001C85.6559 31.4853 86.9213 30.3135 88.4331 30.3135C89.6986 30.3135 90.6015 31.1399 90.6015 33.0838V40.304H92.9683V33.6798C92.9683 31.465 94.2817 30.3339 95.7866 30.3339C97.0589 30.3339 97.9413 31.1602 97.9413 33.1041V40.304H100.308V32.9077C100.308 30.0765 98.7759 28.2477 96.368 28.2477Z"

        fill="#0D67FE"

      ></path>

      <path

        d="M113.107 38.2569C112.785 38.2569 112.635 38.0604 112.635 37.6811V32.5267C112.635 30.0545 111.424 28.2461 107.847 28.2461C104.426 28.2461 102.839 29.9462 102.668 32.3777H105.035C105.186 31.057 106.239 30.2645 107.847 30.2645C109.215 30.2645 110.172 30.8605 110.172 31.802C110.172 32.5674 109.639 33.0347 107.874 33.0347H106.923C104.105 33.0347 102.217 34.1659 102.217 36.7397C102.217 39.4489 104.214 40.6817 106.56 40.6817C108.305 40.6817 109.789 39.9163 110.364 38.2772C110.432 39.4693 111.226 40.3091 112.731 40.3091H114.064V38.2772L113.107 38.2569ZM110.268 34.8703C110.268 37.5118 108.825 38.6565 106.971 38.6565C105.363 38.6565 104.714 37.7218 104.714 36.76C104.714 35.7982 105.274 35.1006 106.998 35.1006H107.34C108.996 35.1006 110.029 34.5248 110.241 33.5224L110.268 34.8703Z"

        fill="#0D67FE"

      ></path>

      <path

        d="M115.535 28.5859V40.3035H117.902V28.5859H115.535Z"

        fill="#0D67FE"

      ></path>

      <path

        d="M126.507 28.2467C125.779 28.2345 125.061 28.4168 124.429 28.7744C123.798 29.132 123.275 29.6516 122.916 30.2786H122.868V28.5853H120.549V40.3029H122.916V33.6991C122.916 31.4842 124.229 30.3125 125.884 30.3125C127.252 30.3125 128.313 31.0575 128.313 33.0827V40.3029H130.68V32.9066C130.68 30.0754 129.086 28.2467 126.507 28.2467Z"

        fill="#0D67FE"

      ></path>

      <path

        d="M138.142 33.2058H137.691C135.639 33.2058 135.242 32.8468 135.242 31.9324C135.242 31.018 136.056 30.2391 137.588 30.2391C137.88 30.2163 138.174 30.2544 138.45 30.3508C138.726 30.4473 138.978 30.6001 139.191 30.7992C139.404 30.9984 139.572 31.2395 139.685 31.507C139.797 31.7746 139.852 32.0625 139.846 32.3523H142.315C142.144 29.9885 140.379 28.2207 137.588 28.2207C134.38 28.2207 132.8 30.0901 132.8 31.9053C132.8 34.0118 134.565 35.2242 136.781 35.2242H137.424C139.476 35.2242 140.092 35.6915 140.092 36.8226C140.092 37.8251 139.059 38.5905 137.513 38.5905C136.895 38.6147 136.293 38.3986 135.834 37.9885C135.375 37.5784 135.096 37.0068 135.057 36.3959H132.588C132.738 38.8275 134.756 40.6156 137.513 40.6156C140.824 40.6156 142.548 38.7191 142.548 36.8226C142.534 34.6755 140.81 33.2058 138.142 33.2058Z"

        fill="#0D67FE"

      ></path>

    </svg>

  );

};